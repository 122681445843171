import React, {
  FC, useState, useEffect, ComponentProps,
} from 'react';
import { Link } from 'gatsby';
import { animateScroll } from 'react-scroll';
import * as styles from './ScrollToTop.module.styl';

interface State {
  isVisible: boolean
}

/**
 * ページトップにスクロールするボタン
 */
const ScrollToTop: FC<ComponentProps<'a'>> = ({ className }) => {
  const [state, setState] = useState<State>({
    isVisible: false,
  });

  const {
    isVisible,
  } = state;

  const scrollToTop = (e: React.MouseEvent<HTMLAnchorElement>):void => {
    e.preventDefault();
    animateScroll.scrollToTop({ duration: 300 });
  };

  useEffect(() => {
    const onScroll = (): void => {
      const OFFSET = 500;
      const y = window.pageYOffset;
      setState({
        ...state,
        isVisible: y > OFFSET,
      });
    };
    window.addEventListener('scroll', onScroll);
    return function cleanup(): void {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setState]);

  return (
    <Link
      onClick={scrollToTop}
      to="#top"
      className={`
        is-block ${styles.pagetop}
        ${isVisible ? styles.pagetopShow : ''}
        ${className}
      `}
    />
  );
};

export default ScrollToTop;
